<template>
  <div class="pageTitle clearfix" :class="{ border: isBorder }">
    <div :style="styles" class="left position" v-if="title">{{ title }}</div>
    <div v-else  class="left position"><slot name="title"></slot></div>
    <div class="right position">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
    isBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.pageTitle {
  min-height: 54px;
  position: relative;
  .left {
    border-left: 3px solid #22d00e;
    padding-left: 15px;
  }
  .right {
    right: 0;
  }
  .position {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.border {
  border-bottom: 1px solid #c0c4cc;
}
</style>