<template>
  <div class="readNameBox" v-loading="loading">
    <pageTitle :title="'实名认证'"></pageTitle>
    <div class="realForm" v-if="!hasReal">
      <p class="readFormTips">
        为响应上海市防疫政策要求，进入物博会场馆前请您完成实名认证：
      </p>
      <div class="realFormContent">
        <el-form
          :model="ruleForm"
          :show-message="false"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item required label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item required label="证件类型" prop="type">
            <el-select v-model="ruleForm.type" style="width: 100%">
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.type"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required label="证件号" prop="id">
            <el-input v-model="ruleForm.id"></el-input>
          </el-form-item>
          <el-form-item
            required
            label="手机号"
            prop="phone"
            v-if="ruleForm.type == 1 && realmsg.showMobile"
          >
            <el-input
              :placeholder="$t('enterTelephone')"
              v-model="ruleForm.phone"
              class="input-with-select"
              type="number"
            >
              <el-select
                v-model="ruleForm.areaCode"
                slot="prepend"
                popper-class="registerAreaCodeList"
                filterable
                :filter-method="areaCodeFilter"
              >
                <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
                >
                  <p class="area_code">{{ item.area_code }}</p>
                  <p class="country textOverflow">
                    {{ item.country | areacodeAddressFormat(LOCALE) }}
                  </p>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <!-- <el-form-item
            required
            label="验证码"
            prop="code"
            v-if="ruleForm.type == 1"
           >
            <div class="ecodeBox">
              <el-input v-model="ruleForm.code"></el-input>
              <el-button
                style="margin-left: 30px"
                @click="send"
                type="success"
                :disabled="!show"
              >
                获取验证码
                <span v-show="!show" class="count">({{ count }}s)</span>
              </el-button>
            </div>
          </el-form-item> -->
          <el-form-item>
            <p class="msgtips">以上信息请和身份证件保持一致</p>
          </el-form-item>
          <el-form-item label="">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              :loading="btnLoading"
              >提交认证</el-button
            >

            <div class="wraning">
              <p><i class="el-icon-warning"></i> 注意事项：</p>
              <p>入场需要身份证件原件，请携带好本人证件。</p>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="" v-else>
      <div class="realId" v-if="realmsg.id_type == 1">
        <p class="rzName">
          尊敬的 <span>{{ realmsg.realname }}</span>
        </p>
        <p class="idTips">恭喜您，成功完成实名认证。祝您参会愉快！</p>
        <div class="msgItem">
          <span class="msgTitle">姓名</span>
          <span class="msgInner">{{ realmsg.realname }}</span>
        </div>
        <div class="msgItem">
          <span class="msgTitle">证件类型</span>
          <span class="msgInner">居民二代身份证</span>
        </div>
        <div class="msgItem">
          <span class="msgTitle">证件号</span>
          <span class="msgInner" v-if="realmsg"
            >{{ realmsg.identity.substr(0, 3) }}******{{
              realmsg.identity.substr(-3, 3)
            }}</span
          >
        </div>
        <div class="msgItem">
          <span class="msgTitle">认证状态</span>
          <span class="msgInner">认证成功</span>
        </div>
        <div class="wraning">
          <p style="margin-bottom: 16px">
            <i class="el-icon-warning"></i> 注意事项：
          </p>
          <p>入场需要身份证件原件，请携带好本人证件。</p>
        </div>
      </div>
      <div class="realId" v-else style="width: 330px">
        <p class="rzName">
          尊敬的 <span>{{ realmsg.realname }}</span>
        </p>
        <p class="idTips">请您详细阅读海外/港澳台人员证件办理说明！</p>
        <div class="blueTips">
          <p class="blueTipsTitle">海外/港澳台人员证件办理说明：</p>
          <p class="blueTipsContent">
            1.出示本人证件原件或电子证明材料<br />
            2.服务台工作人员人工输入ID号及相关信息<br />
            3.服务台现场拍摄本人照片<br />
            4.赋予证件二维码绑定本人照片<br />
            5.获得本人纸质证件
          </p>
        </div>
        <div class="msgItem">
          <span class="msgTitle">姓名</span>
          <span class="msgInner">{{ realmsg.realname }}</span>
        </div>
        <div class="msgItem">
          <span class="msgTitle">证件类型</span>
          <span class="msgInner" v-if="realmsg.id_type == 2">港澳通行证</span>
          <span class="msgInner" v-if="realmsg.id_type == 3">护照</span>
        </div>
        <div class="msgItem">
          <span class="msgTitle">证件号</span>
          <span class="msgInner" v-if="realmsg"
            >{{ realmsg.identity.substr(0, 3) }}******{{
              realmsg.identity.substr(-3, 3)
            }}</span
          >
        </div>

        <div class="wraning">
          <p style="margin-bottom: 16px">
            凭证件二维码1v1调取本人人像，闸机比对入场
          </p>
          <p>绑定本人人像证件不可借给他人使用</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const TIME_COUNT = 60; //更改倒计时时间
import { validateTelephone } from "~/baseUtils/validate";
import pageTitle from "~bac/components/common/pageTitle";
import { areaCodeList } from "~/baseUtils/AddressConfig";
export default {
  components: { pageTitle },
  data() {
    return {
      areaCodeList: [
        {
          id: "1",
          country: "1",
          area_code: "+86",
        },
      ],
      show: true,
      loading: true,
      count: "", // 初始化次数
      timer: null,

      hasReal: false,
      btnLoading: false,
      realmsg: "",
      ruleForm: {
        areaCode: "+86",
        name: "",
        type: "1",
        id: "",
        phone: "",
        code: "",
      },
      options: [
        {
          type: "身份证",
          value: "1",
        },
        {
          type: "港澳通行证",
          value: "2",
        },
        {
          type: "护照",
          value: "3",
        },
      ],
    };
  },
  mounted() {
    console.log(this.USER_INFO);
    this.getUserMsg();
  },
  methods: {
    validateTelephone(phone) {
      return validateTelephone(phone);
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    // async send() {
    //   console.log(validateTelephone(!this.ruleForm.phone));
    //   if (!this.ruleForm.phone) {
    //     this.$message.error("请输入手机号");
    //     return;
    //   }
    //   if (validateTelephone(this.ruleForm.phone)) {
    //     if (!this.timer) {
    //       let result = await this.$store.dispatch("baseStore/getVerifyCode", {
    //         area_code: this.ruleForm.areaCode.replace("+", ""),
    //         mobile: `${this.ruleForm.phone}`,
    //         ctype: 2,
    //       });
    //       if (result.success) {
    //         this.count = TIME_COUNT;
    //         this.show = false;
    //         this.timer = setInterval(() => {
    //           if (this.count > 0 && this.count <= TIME_COUNT) {
    //             this.count--;
    //           } else {
    //             this.show = true;
    //             clearInterval(this.timer); // 清除定时器
    //             this.timer = null;
    //           }
    //         }, 1000);
    //       }
    //     }
    //   } else {
    //     this.$message.error("手机号格式不正确");
    //   }
    // },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.type == 1 && !this.realmsg.mobile) {
            if (!this.ruleForm.phone) {
              this.$message.error("请输入手机号");
              return;
            }
            if (this.ruleForm.areaCode == "+86") {
              if (!validateTelephone(this.ruleForm.phone)) {
                this.$message.error("手机号格式不正确");
                return;
              }
            }
          }

          this.btnLoading = true;
          let data = await this.$store.dispatch("baseConsole/user_realSave", {
            user_id: this.USER_ID,
            realname: this.ruleForm.name,
            id_type: this.ruleForm.type,
            identity: this.ruleForm.id,
            mobile: this.realmsg.mobile || this.ruleForm.phone,
          });
          this.btnLoading = false;
          if (data.is_real) {
            this.getUserMsg();
          } else {
            this.$message.error("姓名和证件号不一致，请重新输入");
          }
        } else {
          return false;
        }
      });
    },
    async getUserMsg() {
      let result = await this.$store.dispatch(
        "baseConsole/user_checkUserInfo",
        {
          user_id: this.USER_ID,
        }
      );
      this.loading = false;
      result.data.showMobile = !result.mobile || !validateTelephone(phone);
      if(result.mobile){
        this.ruleForm.phone = result.mobile
      }
      this.realmsg = result.data;
      if (result.data && result.data.is_real) {
        this.hasReal = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.ecodeBox {
  display: flex;
  align-items: center;
}
.blueTips {
  width: 100%;
  padding: 16px;
  background: #f3f8ff;
  margin-bottom: 16px;
  p {
    color: #3489ff;
  }
  .blueTipsTitle {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .blueTipsContent {
    font-size: 14px;
  }
}
.realId {
  width: 280px;
  margin: 40px auto;
  .rzName {
    font-size: 16px;
    font-weight: 600;
    span {
      color: #027fff;
    }
  }
  .idTips {
    font-size: 14px;
    font-weight: 600;
    padding: 20px 0;
  }
  .msgItem {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .msgTitle {
      width: 110px;
      font-size: 14px;
      color: #999;
    }
    .msgInner {
      font-size: 14px;
    }
  }
}
.readFormTips {
  font-size: 14px;
  color: #027fff;
  text-align: center;
  line-height: 100px;
}
.realForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.demo-ruleForm {
  width: 470px;
}
.msgtips {
  color: #999;
  font-size: 12px;
}
.wraning {
  width: 100%;
  margin: 20px 0;
  background: #fff9e7;
  padding: 12px;
  border: 1px solid #ffbc41;
  color: #ffbc41;
  border-radius: 5px;
  p {
    color: #ffbc41;
    font-size: 12px;
  }
}
</style>
